<template>
  <div>
    <div class="top-bar">
      <div class="text">
        <img src="../img/logo.png" alt="Logo" class="logo">
        本二维码已通过安全验证
      </div>
      
      <div class="background">
        <div class="qrcode">
          <h2>百晓生专用</h2>
          <img :src="qrImage" alt="QR Code">
          <p style="text-align: center;">请使用微信扫描二维码</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
body {
  margin: 0;
}

.text {
  height: 4vh;
  display: flex;
  align-items: center;
  color: rgb(32, 177, 80);
}

.top-bar {
  background: rgb(217, 255, 244);
  width: 100%;
}

.background {
  font-family: Arial, sans-serif;
  background: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  width: 100vw;
}

.qrcode {
  background: white;
  width: 90vw;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.qrcode h2 {
  text-align: center;
  font-size: 2rem;
}

.qrcode img {
  width: 85%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.text img {
  max-width: 7vw;
  height: auto;
  display: block;
  padding: 5px;
}
</style>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      openCount: 0,
      qrImage: ''
    };
  },
  mounted() {
    document.title = '百晓生';

    // 请求当前的打开次数
    fetch('https://qrapi.jxvtc.asia/api/open-count')
      .then(response => response.json())
      .then(data => {
        this.openCount = data.openCount + 1; // 增加访问次数

        // 更新服务器上的打开次数
        fetch('https://qrapi.jxvtc.asia/api/open-count', {
          method: 'POST',
        });

        // 输出打开次数到控制台
        console.log(`网页被打开次数: ${this.openCount}`);
        
        this.updateQrImage();
      });
  },
  methods: {
    updateQrImage() {
      const qrIndex = Math.floor(this.openCount / 200) + 1; // 每200次更换一次
      this.qrImage = `https://qr.jxvtc.asia/imageQR/qr${qrIndex}.png`;
    }
  }
}
</script>

<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
